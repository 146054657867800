<template>
  <div>
    <b-jumbotron
      header="Pour commencer"
      lead="Merci de votre inscription sur ekoworking.com"
    >
      <b-row>
        <b-col cols="6">
          <p>L'étape qui suit est la création de votre entreprise.</p>
          <div class="text-center">
            <img
              width="10"
              :src="require('@/assets/images/pages/ework.svg')"
              alt=""
            />
            <img
              width="10"
              :src="require('@/assets/images/pages/ework.svg')"
              alt=""
            /><img
              width="10"
              :src="require('@/assets/images/pages/ework.svg')"
              alt=""
            />
          </div>
          <div>
            <p>
              Remplissez duement le formulaire en précissant le nom de
              l'entreprise, l'adresse ou la localisation physique, le pays, la
              date de création et si possible, ajoutez un logo.
            </p>
          </div>
          
        </b-col>
        <b-col cols="4" class="ml-auto" style="margin-top: -70px">
          <b-overlay :show="loading" rounded="sm">
            <b-card>
              <validation-observer ref="form">
                <b-form id="form-popover">
                  <b-popover
                    target="form-popover"
                    triggers="manual"
                    placement="topleft"
                    variant="danger"
                    ref="form_popover"
                  >
                    <template #title>Erreur</template>
                    {{ errorMessage }}
                  </b-popover>
                  <ky-input
                    v-model="enterprise.name"
                    :placeholder="'Nom de l\'entreprise'"
                    :rules="'required'"
                  />

                  <ky-input
                    v-model="enterprise.address"
                    :placeholder="'Adresse de l\'entreprise'"
                    :rules="'required'"
                  />

                  <b-form-group
                    id="input-group-1"
                    label="Pays"
                    label-for="input-1"
                  >
                    <b-form-select
                      id="inline-form-custom-select-pref"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      :options="[
                        { name: 'Sélectionnez un pays', value: null },
                        ...countries,
                      ]"
                      value-field="id"
                      text-field="name"
                      v-model="enterprise.country_id"
                    ></b-form-select>
                  </b-form-group>

                  <ky-input
                    :type="'date'"
                    placeholder="Date de création"
                    v-model="enterprise.creation_date"
                    :rules="'required'"
                  />
                  <div class="mt-1 mb-1">
                    <b-form-checkbox  v-model="conditions_accepted">
                      <small class="help-text">
                        J'ai lu et j'accepte
                        <a href="">la politique de confidentialité</a> de
                        ekoworking.com</small
                      >
                    </b-form-checkbox>
                  </div>
                  <div class="text-center">
                    <b-button
                      :disabled="!conditions_accepted"
                      variant="primary"
                      @click="createEnterprise"
                      href="#"
                      class="text-center"
                      >Créer une entreprise</b-button
                    >
                  </div>
                </b-form>
              </validation-observer>
            </b-card>
            <template #overlay>
              <div class="text-center">
                <b-icon
                  icon="stopwatch"
                  font-scale="3"
                  animation="cylon"
                ></b-icon>
                <p id="cancel-label">Enregistrement...</p>
              </div>
            </template>
          </b-overlay>
        </b-col>
      </b-row>
    </b-jumbotron>
  </div>
</template>

<script>
import kyInput from "@/@core/layouts/components/ky-input.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: { kyInput },

  mounted() {
    this.getCountries().then((r) => {
      
    });
  },

  data() {
    return {
      enterprise: {
        country_id: null,
      },
      loading: false,
      errorMessage: "",
      popover_disabled: true,
      conditions_accepted: false,
    };
  },

  computed: {
    ...mapState("auth", ["countries"]),
  },

  methods: {
    async createEnterprise() {
      const valid = await this.$refs.form.validate();
      if (!valid) return;
      this.loading = true;
      this.saveEnterprise(this.$formData(this.enterprise))
        .then((r) => {
          this.loading = false;
          this.$router.replace("/");
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error.message;
          this.popover_disabled = false;
          this.$refs.form_popover.$emit("open");
        });
      //this.$http.post("enterprise",this.enterprise)
    },
    ...mapActions("auth", ["getCountries", "saveEnterprise"]),
  },

  watch: {
    enterprise: {
      handler() {
        this.$refs.form_popover.$emit("close");
      },
      deep: true,
    },
  },
};
</script>